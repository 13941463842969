import { useMemo } from 'react'
import ReactMapGL, {
  Source,
  Layer,
  Popup,
  FlyToInterpolator,
} from 'react-map-gl'

const ExploreMap = ({
  viewport,
  shops,
  popup,
  onClickMap,
  onViewportChange,
}) => {
  const transitionInterpolator = useMemo(() => new FlyToInterpolator())

  return (
    <ReactMapGL
      {...viewport}
      transitionInterpolator={transitionInterpolator}
      mapboxApiAccessToken="pk.eyJ1IjoicG90bHVja2hxIiwiYSI6ImNqeW03OHc3bjBnNTkzbHJvNHVtemhjNW4ifQ._9iIJ5jy_RzZTeks86lQBw"
      mapStyle="mapbox://styles/potluckhq/cjym7i4e00vom1clbjz7at3an"
      width="100%"
      height="100%"
      onViewportChange={onViewportChange}
      onClick={onClickMap}>
      <Source
        id="shops-data"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: shops.data?.map(shop => ({
            id: shop.id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [shop.location.lon, shop.location.lat],
            },
          }))
        }}>
        <Layer
          id="shops-layer"
          type="symbol"
          layout={{
            'icon-image': 'shop-marker-red',
            'icon-anchor': 'bottom',
            'icon-allow-overlap': true,
          }} />
      </Source>
      {popup && (
        <Popup
          latitude={popup.shop.location.lat}
          longitude={popup.shop.location.lon}
          closeButton={true}
          closeOnClick={false}
          onClose={() => {}}
          anchor="top" >
          <div>{popup.shop.name}</div>
        </Popup>
      )}
    </ReactMapGL>
  )
}

export default ExploreMap
